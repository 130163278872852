<template>
  <div class="training-kh">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>考核题库列表</span>
        <el-button
          style="float: right; padding: 5px 5px"
          type="success"
          @click="addTitle"
          >添加题目</el-button
        >
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        stripe
        style="width: 100%"
      >
        <el-table-column prop="title" label="题库名称" align="center">
        </el-table-column>
        <el-table-column prop="start_time" label="开始时间" align="center">
        </el-table-column>
        <el-table-column prop="end_time" label="结束时间" align="center">
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="addtopicVisible(scope.$index, scope.row)">配置题目</el-button>
            <el-button type="text" @click="editor(scope,scope.$index, scope.row)">编辑</el-button>
            <el-button type="text" class="delete"  @click="handleDelete(scope.$index, scope.row)" >删除</el-button >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 弹窗 -->
    <el-dialog title="配置题库信息" :visible.sync="dialogVisible" width="30%" :before-close="handleClose" :close-on-click-modal="false" :close-on-press-escape="false" >
      <!-- <span>这是一段信息</span> -->
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="题库名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="考核时间">
          <div class="block">
            <el-date-picker v-model="form.value1" :value='form.value1' type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd " value-format="yyyy-MM-dd" :default-time="['00:00:00', '23:59:59']" ></el-date-picker>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" v-if="type == 0" @click="configuration">确定</el-button>
        <el-button type="primary" v-else @click="updata">更新</el-button>
      </span>
    </el-dialog>
    <!-- 配置题库弹窗 -->
    <el-dialog title="配置题库信息" :visible.sync="topicVisible" width="30%" :before-close="handleClose" :close-on-click-modal="false" :close-on-press-escape="false" >
      <el-form ref="form" :model="topicForm">
        <el-form-item>
          <template #default>
            <div class="title">题目</div>
            <el-input v-model="topicForm.name"></el-input >
            <i class="el-icon-circle-plus-outline" @click="addtitle" style="font-size:28px;padding-left:10px" ></i>
          </template>
        </el-form-item>
        <el-form-item label="选项A">
          <el-checkbox v-model="checked"><el-input v-model="topicForm.optionsA"></el-input></el-checkbox>
        </el-form-item>
        <el-form-item label="选项B">
          <el-checkbox v-model="checked"><el-input v-model="topicForm.optionsB"></el-input></el-checkbox>
        </el-form-item>
        <el-form-item label="选项C">
          <el-checkbox v-model="checked"><el-input v-model="topicForm.optionsC"></el-input></el-checkbox>
        </el-form-item>
        <el-form-item label="选项D">
          <el-checkbox v-model="checked"><el-input v-model="topicForm.optionsD"></el-input></el-checkbox>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="topicVisible = false">取 消</el-button>
        <el-button type="primary" @click="configuration">确定</el-button>
      </span>
    </el-dialog>
    <!-- 分页 -->
    <div class="pagination">
      <pagination @currentChange="handleCurrentChangeExp" @prevClick='prevClick' @nextClick='nextClick' :startPage='startPage' :total='totalExp'></pagination>
    </div>
  </div>
</template>

<script>
import {examList,examSave,examDelete,examUpdate,examEdit} from '@/api/exam.js'
export default {
  data () {
    return {
      tableData: [],
      dialogVisible: false,
      form: {
        name:'',
        value1:[]
      },
      topicForm: {},
      topicVisible: false,
      checked: false,
      labelList: 1,
      titleList: 1,
      type:0,
      id:null,
      startPage:1,
      totalExp:null
    }
  },
  created() {
    this.getList(this.startPage)
  },
  methods: {
    // 获取列表
    async getList(pages) {
      const res = await examList({page:pages})
      this.tableData = res.data.result.list
      this.totalExp = res.data.result.count
    },
    handleClose (done) {
      done()
      this.form.name = ''
      this.form.value1 = ''
    },
    // 增
    async configuration () {
      const obj = {}
      obj.title = this.form.name
      obj.start_time = this.form.value1[0]
      obj.end_time = this.form.value1[1]
      const res = await examSave(obj)
      if(res.data.code == 1) {
        this.dialogVisible = false
        setTimeout(() => {
          this.getList(this.startPage)
          this.form.name = ''
          this.form.value1 = ''
        }, 500);
      }else if(res.data.code == 40001) {
        this.$message({
          message:'请选择开始时间！',
          type: 'error'
        })
      }
    },
    // 打开
    addTitle() {
      this.type = 0
      if(this.type == 0) {
        this.dialogVisible = true
      }else {
        return
      }
    },
    // 取消
    cancel() {
      this.dialogVisible = false
      this.form.name = ''
      this.form.value1 = ''
    },
    // 删除
    async handleDelete (index, row) {
      const res = await examDelete({id:row.id})
      if(res.data.code == 1) {
        this.$message({
          message: '删除成功！',
          type: 'success'
        })
        this.getList(this.startPage)
      }
    },
    // 改
    async editor(scope,index, row) {
      const res = await examEdit({id:row.id})
      if(res.data.code == 1) {
        this.type = 1
        this.id = row.id
        this.form.value1 = []
        this.form.name = this.tableData[index].title
        this.form.value1[0] = res.data.result.start_time
        this.form.value1[1] = res.data.result.end_time
        this.dialogVisible = true
      }
    },
    // 更新数据
    async updata() {
      const obj = {
        title:this.form.name,
        start_time:this.form.value1[0],
        end_time:this.form.value1[1],
        id:this.id
      }
      const res = await examUpdate(obj)
      if(res.data.code == 1){
        this.dialogVisible = false
          this.getList(this.startPage)
      }
    },
   // 防抖
    debounce (fnEvent, time) {
        var _time = null
        return function () {
          let _arg = arguments
          let _this = this
          if (_time) {
            clearTimeout(_time)
          }
          _time = setTimeout(() => {
            fnEvent.apply(_this, _arg)
          }, time)
        }
      },
    // 跳转配置题目
    configure () {},
    addLabel () {
      if (this.labelList <= 3) {
        this.labelList++
      } else {
        return
      }
    },
    addtitle () {
      this.topicForm = JSON.parse(JSON.stringify(this.topicForm))
      if (this.titleList <= 50) {
        this.titleList++
      } else {
        return
      }
    },
    addtopicVisible (index,row) {
      this.$router.push({
        path:'../topic/addTopic',
        query:{id:row.id}
      })
    },
    handleCurrentChangeExp(size) {
      this.getList(size)
    },
    prevClick(size) {
      this.getList(size)
    },
    nextClick(size) {
      this.getList(size)
    },
  }
}
</script>

<style lang="less" scoped>
.training-kh {
  padding: 20px 0;
  ::v-deep .el-form-item__content {
    display: flex;
    align-items: center;
  }
  .pagination{
    margin-top: 20px;
    text-align: right;
  }
  .title {
    width: 90px;
  }
  .delete {
    color: #f6423b;
  }
}
</style>
